import { mapActions, mapGetters } from 'vuex'
import LOGO_AC from '@/assets/images/prakerja/educational/ac.svg'
import LOGO_BM from '@/assets/images/prakerja/educational/bm.svg'
import LOGO_FT from '@/assets/images/prakerja/educational/ft.svg'
import LOGO_G2 from '@/assets/images/prakerja/educational/g2.svg'
import LOGO_IB from '@/assets/images/prakerja/educational/ib.svg'
import LOGO_MC from '@/assets/images/prakerja/educational/mc.svg'
import LOGO_MS from '@/assets/images/prakerja/educational/ms.svg'
import LOGO_ST from '@/assets/images/prakerja/educational/st.svg'
import LOGO_TE from '@/assets/images/prakerja/educational/te.svg'
import LOGO_TI from '@/assets/images/prakerja/educational/ti.svg'
const FIRST_DATA = 0

export default {
  name: 'redeem-v3',
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    RedeemV3: () => import(/* webpackChunkName: "redeem-v3" */ '@/components/forms/RedeemV3'),
    Chevron: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/Chevron'),
    Search: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/Search')
  },
  data() {
    return {
      step: 1,
      education: null,
      searchTerm: '',
      logos: {
        ac: LOGO_AC,
        bm: LOGO_BM,
        ft: LOGO_FT,
        g2: LOGO_G2,
        ib: LOGO_IB,
        mc: LOGO_MC,
        ms: LOGO_MS,
        st: LOGO_ST,
        te: LOGO_TE,
        ti: LOGO_TI
      }
    }
  },
  mounted() {
    // this.params = this.$route.params.referralcode
    // this.getCodeReferral()
    // if (this.isLoggedIn && this.userData.role_id !== 'STUDENT') {
    //   this.getUser()
    // }
    this.getData()
  },
  computed: {
    ...mapGetters('auth', ['userData', 'userProfile', 'isLoggedIn']),
    ...mapGetters('data', ['educationalInstitutions', 'digitalPlatforms']),
    userId() {
      return localStorage.getItem('user_id')
    },
    slugLp() {
      return this.$route.params.slugLp ? this.$route.params.slugLp : ''
    },
    institutions() {
      const term = this.searchTerm.toLocaleLowerCase().trim()
      return this.educationalInstitutions.filter(item => item.name.toLocaleLowerCase().includes(term))
    }
  },
  methods: {
    ...mapActions('common', ['setThankYouPage', 'showLoading', 'hideLoading']),
    ...mapActions('data', ['getDigitalPlatforms', 'getEducationalInstitutions']),
    async getData() {
      if (this.slugLp.length > 0) {
        this.step = 2
      }
      // this.showLoading()
      await this.getEducationalInstitutions({
        slug: this.slugLp
      })
      // this.hideLoading()
      if (this.slugLp.length > 0) {
        if (this.educationalInstitutions.length > 0) {
          this.education = this.educationalInstitutions[FIRST_DATA]
        } else {
          this.$router.push('/prakerja/redeem')
        }
      }
    },
    selectEducation(education) {
      this.$router.push(`/prakerja/redeem/${education.slug}`)
      // this.education = education
      // this.step++
    }
  }
}
