export default {
  name: 'redeem-failed',
  components: {
    Button: () => import(/* webpackChunkName: "image" */ '@/components/button/Button')
  },
  computed: {
    getMessage() {
      return this.$route.query.message || ''
    }
  },
  methods: {
    toggleCallCenterPrakerja() {
      var url = 'https://bantuan.prakerja.go.id/hc/id/requests/new'
      window.open(url, '_blank').focus()
    },
    handleBack() {
      this.$router.push('/user/my-classes')
    },
    toAdminHelp() {
      window.open(`${window.location.origin}/help`)
    }
  }
}
