<template>
  <div class="redeem">
    <div class="container py-14 mdmax:pb-0 mdmax:pt-10">
      <p class="text-4xl font-bold text-center mb-1 mdmax:text-2xl mdmax:px-4">Redeem</p>
      <p class="text-2xl font-medium text-center mb-16 mdmax:text-xl mdmax:px-4">Redeem your voucher code here</p>
      <div class="form text-center w-2/5 mx-auto mdmax:w-full mdmax:max-w-sm">
        <div class="p-6 mdmax:px-6 bg-grey-lighter rounded-lg">
          <template v-if="!isReadyToSubmit">
            <div class="text-left mb-5">
              <TextField type="text" :error="errors['name']" label="Nama Lengkap" placeholder="Masukkan nama lengkap" v-model="payloads.name" :borderEnabled="true" />
            </div>
            <div class="text-left mb-5">
              <TextField
                type="text"
                :error="errors['email']"
                label="Email"
                placeholder="Masukkan email Anda"
                v-model="payloads.email"
                :disabled="isLoggedIn && userData.role_id !== 'STUDENT'"
                :borderEnabled="true"
              />
            </div>
            <div class="text-left mb-5">
              <TextField type="number" :error="errors['nohp']" label="Nomor Telepon" placeholder="Masukkan nomor telepon Anda" v-model="payloads.nohp" :borderEnabled="true" />
            </div>
            <div class="text-left mb-5">
              <label class="text-xs pl-3 text-left block mb-1">Lembaga Pelatihan</label>
              <div class="relative bg-white">
                <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-0"><Chevron direction="down" width="16" height="16" /></span>
                <select
                  v-model="payloads.customerId"
                  class="border border-grey-field pl-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg appearance-none bg-transparent relative z-1"
                >
                  <option value="" disabled selected hidden style="color: #eee">Pilih Lembaga Pelatihan</option>
                  <option :value="education.code" v-for="education in educationalInstitutions" :key="education.code">{{ education.name }}</option>
                </select>
              </div>
              <div class="text-system-error text-left mt-1 text-xs" v-if="errors['customerId']">{{ errors['customerId'] }}</div>
            </div>
            <div class="text-left mb-5">
              <label class="text-xs pl-3 text-left block mb-1">Platform Digital</label>
              <div class="relative bg-white">
                <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-0"><Chevron direction="down" width="16" height="16" /></span>
                <select
                  v-model="payloads.ecommerce"
                  class="border border-grey-field pl-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg appearance-none bg-transparent relative z-1"
                >
                  <option value="" disabled selected hidden style="color: #eee">Pilih Platform Digital</option>
                  <option :value="item.code" v-for="item in digitalPlatforms" :key="item.code">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="text-system-error text-left mt-1 text-xs" v-if="errors['ecommerce']">{{ errors['ecommerce'] }}</div>
            </div>
            <div class="text-left mb-5">
              <TextField type="text" :error="errors['voucherCode']" label="Kode Voucher" placeholder="Masukkan kode voucher kelas Prakerja" v-model="payloads.voucherCode" :borderEnabled="true" />
            </div>
          </template>
          <template v-else>
            <div class="mb-6">
              <div class="text-lg font-semibold mb-2">Konfirmasi Kode Voucher</div>
              <p class="text-sm text-neutral-500">Pastikan data yang diisi sudah tepat</p>
            </div>
            <div class="form px-10 mdmax:px-0 mb-6">
              <div class="mb-4">
                <label class="block mb-1 text-xs text-neutral-500 text-left">Nama Lengkap</label>
                <p class="font-bold text-left">{{ payloads?.name }}</p>
              </div>
              <div class="mb-4">
                <label class="block mb-1 text-xs text-neutral-500 text-left">Nomor Telepon</label>
                <p class="font-bold text-left">{{ payloads?.nohp }}</p>
              </div>
              <div class="mb-4">
                <label class="block mb-1 text-xs text-neutral-500 text-left">Email</label>
                <p class="font-bold text-left">{{ payloads?.email }}</p>
              </div>
              <div class="mb-4">
                <label class="block mb-1 text-xs text-neutral-500 text-left">Lembaga Pelatihan</label>
                <p class="font-bold text-left">{{ getEducationalInstitutionsName(payloads?.customerId) }}</p>
              </div>
              <div class="mb-4">
                <label class="block mb-1 text-xs text-neutral-500 text-left">Platform Digital</label>
                <p class="font-bold text-left">{{ payloads?.ecommerce }}</p>
              </div>
              <div class="">
                <label class="block mb-1 text-xs text-neutral-500 text-left">Kode Voucher</label>
                <p class="font-bold text-left">{{ payloads?.voucherCode }}</p>
              </div>
            </div>
          </template>
          <div class="text-red-700 mt-2 mb-4 text-sm" v-if="redeemError">{{ redeemError }}</div>
          <div class="text-center mb-5 flex gap-4 justify-center items-center">
            <Button @action="goNext()" type="primary" buttonText="Lanjutkan" v-if="!isReadyToSubmit" />
            <Button @action="doRedeemCode()" type="primary" buttonText="Gunakan Kode" :disabled="isRedeeming" v-if="isReadyToSubmit" />
            <Button
              @action="
                () => {
                  isReadyToSubmit = false
                }
              "
              type="secondary"
              buttonText="Kembali"
              v-if="isReadyToSubmit"
            />
          </div>
          <div class="">
            Ada pertanyaan?
            <a href="https://api.whatsapp.com/send?phone=+6282124304498" target="_blank" class="inline-block text-yellow">Hubungi kami</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isNotEmptyError } from '@/utils'
const CONFIG = {
  name: '"Nama"',
  email: '"Email"',
  nohp: '"Nomor Telepon"',
  voucherCode: '"Kode Voucher"',
  customerId: '"Lembaga Pelatihan"',
  ecommerce: '"Platform Digital"'
}
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "redeem" */ '@/components/icons/Chevron'),
    Button: () => import(/* webpackChunkName: "redeem" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "redeem" */ '@/components/forms/TextField')
  },
  data: () => ({
    payloads: {
      name: '',
      email: '',
      nohp: '',
      voucherCode: '',
      ecommerce: '',
      customerId: ''
    },
    errors: [],
    referralCode: '',
    notification: null,
    isRegisterSuccess: false,
    params: '',
    classTittle: '',
    redeemError: '',
    isRedeeming: false,
    isReadyToSubmit: false
  }),
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  mounted() {
    this.params = this.$route.params.referralcode
    this.getCodeReferral()
    if (this.isLoggedIn && this.userData.role_id !== 'STUDENT') {
      this.getUser()
    }
    this.getData()
  },
  computed: {
    ...mapGetters('auth', ['userData', 'userProfile', 'isLoggedIn']),
    ...mapGetters('data', ['educationalInstitutions', 'digitalPlatforms']),
    userId() {
      return localStorage.getItem('user_id')
    }
  },
  methods: {
    ...mapActions('common', ['setThankYouPage', 'showLoading', 'hideLoading']),
    ...mapActions('prakerja', ['getUserProfile', 'redeemCode']),
    ...mapActions('data', ['getDigitalPlatforms', 'getEducationalInstitutions']),
    getEducationalInstitutionsName(customerId) {
      return this.educationalInstitutions.filter((item) => item.code === customerId)[0]?.name
    },
    getCodeReferral() {
      if (this.params) {
        this.referralCode = this.params === 'redeem' ? '' : this.params
      } else {
        this.referralCode = this.userId
      }
    },
    goNext() {
      if (this.validForm()) {
        // eslint-disable-next-line
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
        if (!regex.test(this.payloads.email)) {
          this.errors['email'] = 'Email format is invalid!'
        } else {
          this.isReadyToSubmit = true
        }
      }
    },
    getUser() {
      this.getUserProfile().then((res) => {
        this.email = res.email
        this.name = res.name
        this.nohp = res.hp
      })
    },
    getData() {
      this.getEducationalInstitutions()
      this.getDigitalPlatforms()
    },

    validForm() {
      const error = isNotEmptyError(this.payloads)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    },
    doRedeemCode() {
      this.redeemError = ''
      this.isRedeeming = true
      this.showLoading()
      this.redeemCode({
        payloads: {
          nama: this.payloads.name,
          email: this.payloads.email,
          hp: this.payloads.nohp,
          provinsi: '',
          kode: this.payloads.voucherCode,
          referral_code: '',
          ecommerce: this.payloads.ecommerce,
          customer_id: this.payloads.customerId
        }
      }).then((response) => {
        this.hideLoading()
        const redeemStatus = response.data.flag
        const message = response.message

        if (!redeemStatus && message && message.toLowerCase() === 'success') {
          this.setThankYouPage({
            data: { title: response.data.kelas, redirect: 'home' }
          })
          this.$router.push('/thankyou/prakerja')
        } else {
          if (redeemStatus && redeemStatus.toLowerCase() === 'rejected') {
            this.redeemError = 'Kode voucher yang Anda masukkan salah! Silakan coba lagi dengan kode voucher yang sesuai.'
          } else if (redeemStatus && redeemStatus.toLowerCase() === 'duplicated') {
            this.redeemError = 'Kode voucher yang Anda masukkan sudah pernah digunakan oleh akun yang lain!'
          } else if (redeemStatus && redeemStatus.toLowerCase() === 're send') {
            this.setThankYouPage({
              data: { title: response.data.kelas, redirect: 'home' }
            })
            this.$router.push('/thankyou/prakerja')
          }
        }
        setTimeout(() => {
          this.isRedeeming = false
        }, 1000)
      })
    }
  }
}
</script>
